html,body{
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
	color: #121212;
  background-color: #E2E8F0;
  overflow-y: scroll;
}

#root{
  height: 100%;
  display: flex;
  flex-direction: column;
}

a:link {
  color: #1976D2;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #1976D2;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #2fceda;
  background-color: transparent;
  text-decoration: underline;
}

ul {
  list-style-type: none;
}

/* h1 {
  text-align: center;
}

nav > * {
  padding: 0 1rem;
}

/* .notification {
  padding: 0 1rem;
  font-size: 2rem;
}

.note-edit {
  color: black;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.indent {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin: 0 0 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.notification {
  color: black;
  background-color: green;
  font-size: 20px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  margin: 0 0 10px;
}

.error {
  color: black;
  background-color: red;
  font-size: 20px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  margin: 0 0 10px;
}

.note-container {
  background-color: #eff2df;
  padding: 1rem;
}

.note-header {
  background-color: #e9f7ed;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 1rem;
}

.chip {
  margin: .5rem;
}

.mystash-flex-item {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
} */
